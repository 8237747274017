import * as THREE from 'three'

import { PointerLockControls } from 'three/addons/controls/PointerLockControls.js';
import { VRButton } from 'three/addons/webxr/VRButton.js';
import { GLTFLoader, OrbitControls, XRControllerModelFactory } from 'three/examples/jsm/Addons.js';
import { CSS3DRenderer } from 'three/addons/renderers/CSS3DRenderer.js';



import { EXRLoader } from 'three/addons/loaders/EXRLoader.js'
import { Group } from 'three/examples/jsm/libs/tween.module.js';
import { BoxLineGeometry } from 'three/addons/geometries/BoxLineGeometry.js';


/**
 * Base
 */

let debug = true

// Variables

let controller1, controller2;
let controllerGrip1, controllerGrip2;

let marker, cabinetFloor, houseFloor, baseReferenceSpace;

let objectGroup = new THREE.Group()
let moveGroup = new THREE.Group()
let collectGroup = new THREE.Group()

let INTERSECTION;
const tempMatrix = new THREE.Matrix4();

const menu = document.querySelector('.selection-container');

// Canvas
const canvas = document.querySelector('canvas.webgl')


// Scene
const scene = new THREE.Scene()


/**
 * Loaders -----------------------------------------------
 */

// LDR cube texture

const cubeTextureLoader = new THREE.CubeTextureLoader()
const environmentMap = cubeTextureLoader.load([
    '/environmentMaps/nursery/nx.jpg',
    '/environmentMaps/nursery/px.jpg',
    '/environmentMaps/nursery/py.jpg',
    '/environmentMaps/nursery/ny.jpg',
    '/environmentMaps/nursery/nz.jpg',
    '/environmentMaps/nursery/pz.jpg'
])

scene.environment = environmentMap
scene.background = environmentMap


/**
 * Textures  -----------------------------------------------
 */
// Controlling Textureloading with the LoadingManager
const loadingManager = new THREE.LoadingManager()

loadingManager.onStart = () => {
    console.log('onStart')
}
loadingManager.onLoad = () => {
    console.log('onLoad')
}
loadingManager.onProgress = () => {
    console.log('onProgress')
}
loadingManager.onError = () => {
    console.log('onError')
}

// loading multiple Textures in the TextureLoader
const textureLoader = new THREE.TextureLoader(loadingManager)

const cabinetColorTexture = textureLoader.load('/textures/cabinet/color.jpg')
cabinetColorTexture.colorSpace = THREE.SRGBColorSpace

const cabinetNormalTexture = textureLoader.load('/textures/cabinet/normal.png')
cabinetNormalTexture.colorSpace = THREE.SRGBColorSpace

const floorColorTexture = textureLoader.load('/textures/floor/color.jpg')
floorColorTexture.colorSpace = THREE.SRGBColorSpace

const floorNormalTexture = textureLoader.load('/textures/floor/normal.jpg')
floorNormalTexture.colorSpace = THREE.SRGBColorSpace

const floorRoughnessTexture = textureLoader.load('/textures/floor/roughness.jpg')
floorRoughnessTexture.colorSpace = THREE.SRGBColorSpace

floorColorTexture.minFilter = THREE.NearestFilter

const diceColorTexture = textureLoader.load('/textures/dices/color.jpg')


floorColorTexture.rotation = Math.PI / 2
floorNormalTexture.rotation = Math.PI / 2
floorRoughnessTexture.rotation = Math.PI / 2


/**
 * Geometries  -----------------------------------------------
 */


// Floor
const cabinetMaterial = new THREE.MeshBasicMaterial( {map: cabinetColorTexture, side: THREE.DoubleSide} );

const floorMaterial = new THREE.MeshStandardMaterial()
floorMaterial.metalness = 1
floorMaterial.roughness  = 1
floorMaterial.scale = 2
floorMaterial.side = THREE.DoubleSide
floorMaterial.map = floorColorTexture
floorMaterial.roughnessMap = floorRoughnessTexture
floorMaterial.normalMap = floorNormalTexture
floorMaterial.transparent = true

const diceMaterial = new THREE.MeshStandardMaterial({map: diceColorTexture})


// Cabinet Floor
const cabinetFloorGeometry = new THREE.PlaneGeometry(50,30)
cabinetFloor = new THREE.Mesh(cabinetFloorGeometry, cabinetMaterial)
cabinetFloor.rotation.x = Math.PI/2
cabinetFloor.position.y = -0.02
cabinetFloor.position.x = 3
// scene.add(cabinetFloor)
scene.add(cabinetFloor)

// House Floor
const houseFloorGeometry = new THREE.PlaneGeometry(10.5,2.7)
houseFloor = new THREE.Mesh(houseFloorGeometry, floorMaterial)
houseFloor.rotation.x = Math.PI/2
houseFloor.position.y = 0.01
houseFloor.position.x = 3
houseFloor.scale.set(1.5,1.5,1.5)
scene.add(houseFloor)

// Marker
const markerMaterial = new THREE.MeshBasicMaterial( { color: 0xbcbcbc } )
const markerGeometry = new THREE.CircleGeometry( 0.25, 32 ).rotateX( - Math.PI / 2 )
marker = new THREE.Mesh(markerGeometry,markerMaterial)
scene.add(marker);

// Cubes
const cubeGeometry = new THREE.BoxGeometry(0.5, 0.5, 0.5);
const cube1 = new THREE.Mesh(cubeGeometry, diceMaterial);
cube1.position.set(0,0.5,-3)

const cube2 = new THREE.Mesh(cubeGeometry, diceMaterial);
cube2.position.set(0.1,1.5,-3.2)

const cube3 = new THREE.Mesh(cubeGeometry, diceMaterial);
cube3.position.set(-1.3,0.5,-2.5)

moveGroup.add(cube1, cube2, cube3);


/**
 * Sizes  -----------------------------------------------
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Lights  -----------------------------------------------
 */

// Ambient light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)

// Directional light
const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
directionalLight.position.set(5, 5, 5)
scene.add(directionalLight)

const lightsGroup = new THREE.Group();

// PointLight Settings
let lightColor = '#ffdcda'
let lightIntensity = 1
let candleColor = 'orange'
let candleIntensity = 0.7

// living Room Lights
const livingroomPointLight = new THREE.PointLight( lightColor, lightIntensity , 100 );
livingroomPointLight.position.set( 4.8, 2.1, 0.4 );
livingroomPointLight.castShadow = true
objectGroup.add(livingroomPointLight)

// const livingroomSphereSize = 1;
// const livingroomPointLightHelper = new THREE.PointLightHelper( livingroomPointLight, livingroomSphereSize );
// scene.add( livingroomPointLightHelper );


// kitchen Lights
const kitchenPointLight = new THREE.PointLight( lightColor, lightIntensity, 100 );
kitchenPointLight.position.set( -0.7, 2.1, 0.4 );
objectGroup.add(kitchenPointLight)

// const kitchensphereSize = 1;
// const kitchenpointLightHelper = new THREE.PointLightHelper( kitchenPointLight, kitchensphereSize );
// scene.add( kitchenpointLightHelper );

// kitchen Candle
const candleKitchenPointLight = new THREE.PointLight( candleColor, candleIntensity, 100 ); 
candleKitchenPointLight.position.set( -2.85, 1, 0.6 );
objectGroup.add(candleKitchenPointLight)

// const candleKitchensphereSize = 1;
// const candleKitchenpointLightHelper = new THREE.PointLightHelper( candleKitchenPointLight, candleKitchensphereSize );
// scene.add( candleKitchenpointLightHelper );

// hallway Lights
const hallwayPointLight = new THREE.PointLight( lightColor, lightIntensity, 100 );
hallwayPointLight.position.set( 0, 4.8, 0.4 );
objectGroup.add(hallwayPointLight)

// const hallwaysphereSize = 1;
// const hallwaypointLightHelper = new THREE.PointLightHelper( hallwayPointLight, hallwaysphereSize );
// scene.add( hallwaypointLightHelper );

// bedroom Lights
const bedroomPointLight = new THREE.PointLight( lightColor, lightIntensity, 100 );
bedroomPointLight.position.set( 5.2, 4.8, 0.4 );
objectGroup.add(bedroomPointLight)


// const bedroomsphereSize = 1;
// const bedroompointLightHelper = new THREE.PointLightHelper( bedroomPointLight, bedroomsphereSize );
// scene.add( bedroompointLightHelper );

// attic Lights
const atticPointLight = new THREE.PointLight( lightColor, lightIntensity, 100 );
atticPointLight.position.set( 3.1, 7.4, 0.4 );
objectGroup.add(atticPointLight)

// const atticsphereSize = 1;
// const atticpointLightHelper = new THREE.PointLightHelper( atticPointLight, atticsphereSize );
// scene.add( atticpointLightHelper );

// attic Candle
const candleAtticPointLight = new THREE.PointLight( candleColor, candleIntensity, 100 ); 
candleAtticPointLight.position.set( 1, 6.4, 0.9 );
objectGroup.add(candleAtticPointLight)

// const candleAtticsphereSize = 1;
// const candleAtticpointLightHelper = new THREE.PointLightHelper( candleAtticPointLight, candleAtticsphereSize );
// scene.add( candleAtticpointLightHelper );



/**
 * Camera  -----------------------------------------------
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0,1.6,0)
scene.add(camera)

if(debug == true) {
    // Orbit 
    const controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true
}

if(debug == false) {
    // PointerLock Controls
        const controls = new PointerLockControls(camera, document.body);
        scene.add(controls.getObject());
        
        const instructions = document.createElement('div');
        instructions.style.display = 'flex'
        instructions.style.justifyContent = 'center'
        instructions.style.alignItems = 'center'
        instructions.style.position = 'absolute';
        instructions.style.height = '100%'
        instructions.style.width = '100%';
        instructions.style.textAlign = 'center';
        instructions.style.textShadow = '1px 1px 2px black';
        instructions.style.fontSize = '24px';
        instructions.style.color = 'white';
        instructions.style.backgroundColor = '#0000002d';
        instructions.style.cursor = 'pointer';
        instructions.style.padding = '20px';
        instructions.style.fontFamily = 'sans-serif'
        instructions.innerHTML = 'Click to look around';
        document.body.appendChild(instructions);
        
        instructions.addEventListener('click', function() {
            controls.lock();
        });
        
        controls.addEventListener('lock', function() {
            instructions.style.display = 'none';
        });
        
        controls.addEventListener('unlock', function() {
            instructions.style.display = 'flex';
        });
   
}



/**
 * Models  -----------------------------------------------
 */


const houseGltfLoader = new GLTFLoader()
const carGltfLoader = new GLTFLoader()


let dollhouseFurniture
var currentRoom = null

houseGltfLoader.load(
    '/models/dollhouse/gltf/dollhouse.gltf',
    (gltf) =>
    {
        gltf.scene.rotation.set(0, Math.PI / 2, 0 )
        gltf.scene.scale.set(2.65,2.65,2.65)
        gltf.scene.position.set(2, 0, 0)
        objectGroup.add(gltf.scene)

        dollhouseFurniture = gltf.scene.children
        console.log(dollhouseFurniture, 'dollhouseFurnitureInside')


        // Event-Listener to change between rooms 
        menu.addEventListener('click', (event) => {
            const roomClasses = event.target.className;
            const roomName = roomClasses.substr(15, 20);
            currentRoom = roomName;
            console.log(currentRoom)


        // Switching house Position: Normal x:2 y:0 z:0
        function changePerspectiveRoom(room) {
            if (room === 'third-floor') {
                console.log('Switching to attic perspective');
                cabinetFloor.position.y = -8.1
                objectGroup.position.set(0, -8.02, 0)
                moveGroup.position.y = -8
                collectGroup.position.y = -8
                debug = false



            } else if (room === 'second-floor') {
                console.log('Switching to bedroom perspective');
                cabinetFloor.position.y = -4.1
                objectGroup.position.set(0, -4, 0)
                moveGroup.position.y = -4
                collectGroup.position.y = -4
                debug = false


            } else if (room === 'first-floor') {
                console.log('Switching to kitchen perspective');
                cabinetFloor.position.y = -0.1
                lightsGroup.position.set(0, 0, 0)
                objectGroup.position.set(0, 0, 0)
                moveGroup.position.y = 0
                collectGroup.position.y = 0
                debug = false


            }
        }
        changePerspectiveRoom(currentRoom);

        // Hide the menu
        if (currentRoom.length > 2) {
            menu.classList.toggle('hidden');
        }
        });
    }
)

// GLB Car from ObjectCapture API
carGltfLoader.load(
    '/models/cartoyPolycam.glb',
    (gltf) =>
    {
        gltf.scene.scale.set(2.5,2.5,2.5)
        gltf.scene.rotation.set(0, 2, 0)
        gltf.scene.position.set(-4, 4, 0)
        collectGroup.add(gltf.scene)
    }
)
objectGroup.scale.set(1.5,1.5,1.5)
scene.add(objectGroup)
scene.add(moveGroup)
scene.add(collectGroup)





/**
 * Renderer  -----------------------------------------------
 */

const renderer = new THREE.WebGLRenderer( { antialias: true } );
renderer.setPixelRatio( window.devicePixelRatio );
renderer.setSize( window.innerWidth, window.innerHeight );

/**
 * VR  -----------------------------------------------
 */

// Controller
// Controller 1 wird erstellt und der Szene hinzugefügt
controller1 = renderer.xr.getController(0);
controller1.addEventListener('selectstart', onSelectStart);
controller1.addEventListener('selectend', onSelectEnd);
controller1.addEventListener('connected', function(event) {
    this.add(buildController(event.data));
    console.log('Controller 1 connected', event);
});
controller1.addEventListener('disconnected', function() {
    this.remove(this.children[0]);
    console.log('Controller 1 disconnected');
});
scene.add(controller1);

// Controller 2 wird erstellt und der Szene hinzugefügt
controller2 = renderer.xr.getController(1);
controller2.addEventListener('selectstart', onSelectStart);
controller2.addEventListener('selectend', onSelectEnd);
controller2.addEventListener('connected', function(event) {
    this.add(buildController(event.data));
    console.log('Controller 2 connected', event);
});
controller2.addEventListener('disconnected', function() {
    this.remove(this.children[0]);
    console.log('Controller 2 disconnected');
});
scene.add(controller2);


const controllerModelFactory = new XRControllerModelFactory();

controllerGrip1 = renderer.xr.getControllerGrip(0);
controllerGrip1.add(controllerModelFactory.createControllerModel(controllerGrip1));
scene.add(controllerGrip1);

controllerGrip2 = renderer.xr.getControllerGrip(1);
controllerGrip2.add(controllerModelFactory.createControllerModel(controllerGrip2));
scene.add(controllerGrip2);

window.addEventListener('resize', onWindowResize, false);

// Base Referenz wird gespeichert
renderer.xr.addEventListener('sessionstart', () => {
    baseReferenceSpace = renderer.xr.getReferenceSpace();
    console.log('Session started');
});

renderer.xr.enabled = true;
const raycaster = new THREE.Raycaster();

document.body.appendChild(renderer.domElement);
document.body.appendChild(VRButton.createButton(renderer));


// Start der Selektion
function onSelectStart(event) {
    this.userData.isSelecting = true;

    const controller = event.target;
    const intersections = getIntersections(controller);


    if (intersections.length > 0 ) {
        const intersection = intersections[0];
        console.log('intersection',intersection)
        const object = intersection.object;
        controller.attach(object);
        console.log('object',object)

        controller.userData.selected = object;
    }

    controller.userData.targetRayMode = event.data.targetRayMode;
}

// Ende der Selektion
function onSelectEnd(event) {
    this.userData.isSelecting = false;

    const controller = event.target;

    if (controller.userData.selected !== undefined) {
        const object = controller.userData.selected;
        objectGroup.attach(object);

        controller.userData.selected = undefined;
    } 
    
    else if (INTERSECTION) {
        // Teleportation falls kein Objekt selektiert ist
        const offsetPosition = { x: -INTERSECTION.x, y: -INTERSECTION.y, z: -INTERSECTION.z, w: 1 };
        const offsetRotation = new THREE.Quaternion();
        const transform = new XRRigidTransform(offsetPosition, offsetRotation);
        const teleportSpaceOffset = baseReferenceSpace.getOffsetReferenceSpace(transform);

        renderer.xr.setReferenceSpace(teleportSpaceOffset);
    }
}

function getIntersections(controller) {
    controller.updateMatrixWorld();
    raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    raycaster.ray.direction.set(0, 0, -1).applyMatrix4(controller.matrixWorld);
    return raycaster.intersectObjects(moveGroup.children , false);
}



let highlightedObject

function highlightObject(object) {
    if (object) {
        console.log('hovered object', object)
        console.log('object hovered')
        object.material.emissive = new THREE.Color(0x555555); // oder eine andere Farbe/Helligkeit
    }
}

function unhighlightObject(object) {
    if (object) {
        console.log('object not hovered')
        object.material.emissive = new THREE.Color(0x000000); // Standardfarbe
    }
}


// Raylinien werden hinzugefügt
function buildController(data) {
  
    let geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0, 0, 0, -1], 3));
    // geometry.setAttribute('color', new THREE.Float32BufferAttribute([0.5, 0.5, 0.5, 0, 0, 0], 3));
    let material = new THREE.LineBasicMaterial({ color: 0xffffff, blending: THREE.AdditiveBlending });
    return new THREE.Line(geometry, material);
}

// Resize des Fensters Funktion
function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
}


function animate() {
    INTERSECTION = undefined;

    // Überprüfung ob Controller1 selektiert ist
    if (controller1.userData.isSelecting === true) {
        tempMatrix.identity().extractRotation(controller1.matrixWorld);

        // Raycaster wird durchgeführt 
        raycaster.ray.origin.setFromMatrixPosition(controller1.matrixWorld);
        raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);

        const intersects = raycaster.intersectObjects([houseFloor]);
        
        // Überprüfung ob ein Objekt getroffen wurde
        if (intersects.length > 0) {
            INTERSECTION = intersects[0].point;
        }
    }

    // Highlighting der Objekte bei Hover
    const hoverIntersections = getIntersections(controller2);
    if (hoverIntersections.length > 0) {
        const hoveredObject = hoverIntersections[0].object;
        if (highlightedObject !== hoveredObject) {
            unhighlightObject(highlightedObject);
            highlightObject(hoveredObject);
            highlightedObject = hoveredObject;
            console.log('hovered object:' ,hoveredObject)
            console.log('highlighted object:',highlightedObject)
        }
    } else {
        unhighlightObject(highlightedObject);
        highlightedObject = null;
    }

    if (INTERSECTION) marker.position.set(INTERSECTION.x, INTERSECTION.y + 0.01, INTERSECTION.z);

    

    marker.visible = INTERSECTION !== undefined;

    renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);




/**
 * HTML DOM MANIPULATIONS  -----------------------------------------------
 */

const menuButton = document.querySelector('.menu-button');

menuButton.addEventListener('click', () => {
    menu.classList.toggle('hidden');
});


/**
 * Debug  -----------------------------------------------
 */

if(debug == true) {
    camera.position.set(2, 4, -10)
    camera.lookAt(0,0,0)
    // camera.rotation.y = Math.PI
    // camera.lookAt(4.7, 3.7, 0)
}

